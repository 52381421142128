<template>
  <div class="equipmentQuery">
    <div class="gradientLayer"></div>
    <div class="mainLayer">
      <div class="search">
        <v-search
          v-model="deviceName"
          @onSearch="onSearch"
          placeholder="请输入设备名称"
        ></v-search>
      </div>
      <div class="nav">
        <div class="nav-name">设备名称</div>
        <div class="nav-drop">
          <v-dropDownMenu
            v-model="requestData.status"
            :option1="statuOps"
            @change="change"
          ></v-dropDownMenu>
        </div>
      </div>
      <div class="list">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div v-for="(item, index) in listData" :key="index" class="oItem">
            <div class="item-name">
              <span>{{ item.deviceName }}</span>
            </div>
            <div
              class="item-status"
              :class="item.status == 1 ? 'online' : 'offline'"
            >
              <span>{{ statusMap[item.status] }}</span>
            </div>
          </div>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { setStatusOps, statusMap } from "./map";
import { getListURL } from "./api";

export default {
  data() {
    return {
      isLoading: false,
      finished: false,
      finishedText: "没有更多了",
      listData: [],
      deviceName: "", // 查询
      requestData: {
        curPage: 1,
        pageSize: 20,
        status: "",
      },
      statuOps: setStatusOps(),
      statusMap,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  methods: {
    onSearch() {
      this.requestData.curPage = 1;
      this.finished = false;
      // this.requestData.status = "";
      this.listData = [];
      this.onLoad();
    },
    change(val) {
      this.requestData.curPage = 1;
      this.finished = false;
      // this.$refs.list._data.loading = true;
      this.requestData.status = val;
      this.listData = [];
      this.onLoad();
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        // houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        ...this.requestData,
      };
      if (this.deviceName) {
        params.deviceName = this.deviceName;
      }
      this.$axios
        .get(getListURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.requestData.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.requestData.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.requestData.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.equipmentQuery {
  position: relative;
  background-color: #f6fafa;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .gradientLayer {
    width: 100%;
    height: 750px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      180deg,
      rgba(192, 211, 255, 0.64),
      rgba(245, 248, 255, 0)
    );
  }
  .mainLayer {
    box-sizing: border-box;
    width: 100%;
    padding: 40px 30px;
    position: absolute;
    left: 0;
    top: 0;
    .search {
      ::v-deep .van-search {
        background-color: transparent;
        padding-left: 0;
        .van-search__content {
          border-radius: 34px;
        }
      }
    }
    .nav {
      border-radius: 16px;
      box-sizing: border-box;
      padding: 0 24px;
      margin-top: 40px;
      display: flex;
      color: rgba(54, 128, 255, 1);
      font-size: 28px;
      background: #fff;
      line-height: 96px;
      > div.nav-name {
        flex: 1;
      }
      .nav-drop {
        padding-right: 8px;
      }
      ::v-deep .v-dropDownMenu {
        .van-dropdown-menu__bar {
          box-shadow: none;
          height: 96px;
          .van-dropdown-menu__title {
            line-height: 96px;
            font-size: 28px;
            color: rgba(54, 128, 255, 1);
            // &::after {
            //   right: -12px;
            //   margin-top: -6px;
            //   border: 6px solid;
            // }
          }
        }
      }
    }
    .list {
      margin-top: 40px;
      background: #fff;
      border-radius: 16px;
      padding: 20px 32px 20px 32px;
      box-shadow: 0px 4px 16px 0 rgba(231, 231, 231, 0.5);
      .oItem {
        font-family: "Microsoft YaHei";
        font-size: 28px;
        padding: 40px 0;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
        display: flex;
        .item-name {
          flex: 1;
          padding: 0 60px 0 0;
        }
        .item-status {
          width: 80px;
          min-width: 80px;
          // text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            display: block;
            min-width: 100%;
            position: relative;
            &::after {
              content: "";
              display: block;
              background: rgba(102, 185, 90, 1);
              width: 12px;
              height: 12px;
              position: absolute;
              top: 2px;
              right: 4px;
              border-radius: 50%;
            }
          }
          &.online {
          }
          &.offline {
            color: rgba(0, 0, 0, 0.5);
            span {
              &::after {
                background: rgba(0, 0, 0, 0.25);
              }
            }
          }
        }
      }
    }
  }
}
</style>

import { mapHelper } from "@/utils/utils.js";

// 	商圈类型
const businessTypeops = [
  {
    value: 1,
    text: "商铺",
  },
  {
    value: 2,
    text: "服务者",
  },
];

const { map: businessTypeopsMap, setOps: businessTypeopsOps } =
  mapHelper.setMap(businessTypeops);

const status = [
  {
    value: "",
    text: "设备状态",
  },
  {
    value: 1,
    text: "在线",
  },
  {
    value: 0,
    text: "离线",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

export { businessTypeopsMap, businessTypeops, statusMap, setStatusOps };
